import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelopeOpen,
  faWebcam,
} from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import Obfuscate from "react-obfuscate";

import Layout from "../components/layout";
import SEO from "../components/seo";
import LinkButton from "../components/LinkButton";

function ContactPage() {
  return (
    <Layout>
      {() => (
        <>
          <SEO keywords={[]} title="Contact" />
          <div className="relative bg-white text-left">
            <div className="absolute inset-0">
              <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-100"></div>
            </div>
            <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
              <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-20 xl:pr-12">
                <div className="max-w-lg mx-auto">
                  <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                    Get in touch
                  </h2>
                  <p className="mt-3 text-lg leading-6 text-gray-600">
                    Did my services strike a chord with you? Let&apos;s talk!
                  </p>
                  <dl className="mt-8 text-base leading-6 text-gray-600">
                    <div>
                      <dt className="sr-only">Postal address</dt>
                      <dd>
                        <p>Minthesize e.U.</p>
                        <p>DI Julian Rubisch</p>
                        <p>Keilgasse 6/1</p>
                        <p>1030 Wien</p>
                        <p>Austria</p>
                      </dd>
                    </div>
                    <div className="mt-6">
                      <dt className="sr-only">Phone number</dt>
                      <dd className="flex items-center">
                        <FontAwesomeIcon icon={faPhone} />
                        <span className="ml-3">+43 664 407 55 11</span>
                      </dd>
                    </div>
                    <div className="mt-3">
                      <dt className="sr-only">Email</dt>
                      <dd className="flex items-center">
                        <FontAwesomeIcon icon={faEnvelopeOpen} />
                        <span className="ml-3">
                          <Obfuscate email="info@minthesize.com" />
                        </span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div className="rounded-lg shadow-lg bg-white pt-8 pb-16 px-4 sm:px-6 lg:col-span-3 lg:pt-12 lg:pb-20 lg:px-8 xl:pl-12">
                <div className="max-w-lg mx-auto lg:max-w-none">
                  <div className="bg-gray-100 rounded-lg mb-8">
                    <div className="px-4 py-5 sm:p-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        <FontAwesomeIcon icon={faWebcam} />
                        <span className="ml-2">Prefer a Call?</span>
                      </h3>
                      <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>
                          If you&apos;d like to explain your request in a video
                          message, you can now do so via ZipMessage:
                        </p>
                      </div>
                      <div className="mt-5">
                        <LinkButton
                          url="https://zipmessage.com/s2nxttna"
                          target="_blank"
                        >
                          Send me a video message
                        </LinkButton>
                      </div>
                    </div>
                  </div>
                  <form
                    name="contact"
                    method="post"
                    className="grid grid-cols-1 row-gap-6"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    <div>
                      <label htmlFor="full_name" className="sr-only">
                        Full name
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <input
                          id="full_name"
                          name="full_name"
                          className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                          placeholder="Full name"
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="message" className="sr-only">
                        Message
                      </label>
                      <div className="relative rounded-md shadow-sm">
                        <textarea
                          id="message"
                          name="message"
                          rows="4"
                          className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div className="">
                      <span className="inline-flex rounded-md shadow-sm">
                        <button
                          type="submit"
                          className="inline-flex justify-center py-3 px-6 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-700 hover:bg-gray-600 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out"
                        >
                          Let&apos;s talk
                        </button>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}

export default ContactPage;
